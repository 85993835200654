import React from "react";
import SEO from "../components/functional/seo";
import DesignProps from "../components/molecules/design-props";
import MediaCarousel from "../components/organisms/media-carousel";
import Button from "../components/atoms/button";
import DesignMenu from "./design-menu.json";

function DesignMediaGroup() {
  const mediaGroupMenu = DesignMenu.find((dm) => dm.slug === "media-group");
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Media Groups" />
      <h2>Carousels</h2>
      <p className="design__text p2">
        Media Groups &amp; Carousels consist of a group of Media Blocks that are
        grouped together, or have the ability to be scrolled by swiping or
        button navigation. They are used as a gallery of information.
      </p>
      <div className="sp-btm-mrg-m">
        {mediaGroupMenu.submenu.map((item) => (
          <Button
            className="shared__link"
            route={`/${item.slug}`}
            key={item.slug}>
            {item.title}
          </Button>
        ))}
      </div>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignProps
            component={MediaCarousel}
            title="Media Carousel Properties"
          />
        </div>
      </div>
    </div>
  );
}

export default DesignMediaGroup;
